export enum StatusEffectsEnum {
    PRONE,
    POISONED,
    BLIND,
    CHARMED,
    DEAFENED,
    FRIGHTENED,
    GRAPPLED,
    INCAPACITATED,
    INVISIBLE,
    PARALYZED,
    PETRIFIED,
    RESTRAINED,
    STUNNED,
    UNCONSCIOUS,
    HEX,
    HEXBLADE,
    UNARMED,
    RAGE,
    CONCENTRATION
}
