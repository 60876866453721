export enum ColorMarkerEnum {
    NONE,
    BLACK,
    GREY,
    PURPLE,
    RED,
    PINK,
    ORANGE,
    YELLOW,
    GREEN,
    BLUE,
    WHITE
}
